import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getGameDetails } from "../../Utilies/api";
import full_icon from "../../assets/fullscreen_icon.png";
import "./IframeGame.css";

const IframeGame = () => {
  // get game id
  let param = useParams();
  console.log(param);
  // get user token
  const token = localStorage.getItem("token");
  // get user id
  let userId = JSON.parse(localStorage.getItem("user_id"));
  let gameId = param.id || param.gameId;
  let contestId = param.tournId;

  const [gameDetails, setGameDetails] = useState("");
  async function gameItemDetails(gameId, token) {
    try {
      let result = await getGameDetails(gameId, token);
      setGameDetails(result);
    } catch (error) {}
  }
  // full screen
  // const [goFull, setGoFull] = useState(false);
  useEffect(() => {
    gameItemDetails(gameId, token);
    let e = document.getElementById("my-fullScreen");
    e?.requestFullscreen();
  }, [gameId, token]);

  return (
    <div className="iframe-container">
      {/* <button
        id="btn"
        className="full"
        onClick={() => {
          setGoFull(!goFull);
        }}
      >
        <img src={full_icon} alt="full-icon" />
      </button> */}
      <object
        type="text/html"
        data={
          gameDetails.folder +
          "?user_id=" +
          userId +
          "&game_id=" +
          gameId +
          "&game_text=" +
          gameDetails.game_id +
          "&contest_id=" +
          contestId
        }
        scrolling="no"
        tabindex="0"
        id="my-fullScreen"
        allowFullScreen
      ></object>
    </div>
  );
};

export default IframeGame;
