import React, { useEffect, useRef, useState } from "react";
import "./voucherGame.css";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import { getCategoryDetails, updateCartItem } from "../../Utilies/api";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
// import Cards from "react-credit-cards-2";
import { AiOutlineHeart } from "react-icons/ai";
import { BiShoppingBag } from "react-icons/bi";
import { ToastContainer } from "react-toastify";
import { notify, notify1 } from "../../Components/ToastComponent.js";
import Navbar from "../../Components/Navbar/NavbarComp.jsx";
import { IoMdArrowRoundBack } from "react-icons/io";
import VoucherTabs from "../../Components/VoucherTabs/VoucherTabs.jsx";
import SinglePayment from "../../Components/SinglePayment/SinglePayment.jsx";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading/Loading.jsx";
import { postCartItem, postWishListItem } from "../../Utilies/gamerClubApi.js";

const VoucherGame = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [arr, setArr] = useState([]);
  const navigate = useNavigate();
  // react slick settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  let x = useParams();
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };

  // show payment modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (inputValue > 0) {
      setShow(true);
    } else {
      notify1("You Should Add Item Quantity");
    }
  };
  // increament & decreament voucher quantity
  async function decreaseAmount(object_id, type, amounts) {
    amounts = amounts - 1;
    let formData = {
      player_id: userId,
      object_id: object_id,
      qty: amounts,
      type: type,
    };
    if (formData.qty >= 1) {
      let result = await updateCartItem(formData, token);
      setInputValue(result.data.updated_records.qty);
    }
  }
  async function increaseAmount(object_id, type, amounts) {
    amounts = amounts + 1;
    let formData = {
      player_id: userId,
      object_id: object_id,
      qty: amounts,
      type: type,
    };
    if (formData.qty <= 8) {
      let result = await updateCartItem(formData, token);
      setInputValue(result.data.updated_records.qty);
    }
  }

  // get user id
  let userId = JSON.parse(localStorage.getItem("user_id"));
  // get user token
  const token = localStorage.getItem("token");

  // store category vouchers inside state
  const [categoryVouchers, setCategoryVouchers] = useState([]);
  // set index in state to show voucher details
  const [index, setIndex] = useState(0);
  // store Subcategory Data inside state
  const [categoryData, setCategoryData] = useState([]);
  const [categoryDataRedeem, setCategoryDataRedeem] = useState("");
  const [inputValue, setInputValue] = useState(1);
  const handleInputChangeQty = (e) => {
    const value = e.target.value;
    // Check if the input is a valid number based on your specified criteria
    if (/^[1-9]$/.test(value) || value === "") {
      setInputValue(value);
    }
  };

  const getDetails = async (id, token, langType) => {
    try {
      let translations = await getCategoryDetails(id, token, langType);
      i18n.addResourceBundle(i18n.language, "translation", translations);
      setCategoryData(translations.data.category);
      setCategoryDataRedeem(
        translations.data.category.redeemSteps_en ||
          translations.data.category.redeemSteps_ar
      );
      setCategoryVouchers(translations.data.vouchers);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // store svg icon color
  // add to cart`
  async function addToCart(
    sectionType,
    token,
    productId,
    cartItemType,
    voucherQty
  ) {
    setArr([...arr, productId]);
    let formData = {
      items: [
        {
          object_id: productId,
          qty: voucherQty,
          type: cartItemType,
        },
      ],
    };
    await postCartItem(formData, sectionType, token);
  }

  // add to wishlist
  async function addToWishlist(token, productId, cartItemType) {
    try {
      let formData = {
        object_id: productId,
        type: cartItemType,
      };
      await postWishListItem(formData, token);
      notify("Your Item Added To WishList");
    } catch (error) {
      notify1(error?.response?.data?.error);
    }
  }

  // focus on input
  const inputRef = useRef(null);
  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    getDetails(x.id, token, i18n.language);
    handleFocus();
  }, [x.id, token, i18n.language, i18n]);

  return (
    <>
      <Navbar title={categoryData.name_en} />
      <div className="voucher" style={{ paddingTop: "150px" }}>
        <div className="container">
          <SideMenuBar sideBar={sideBar} showSideBar={showSideBar} />
          {/* category & voucher details */}
          {isLoading ? (
            <div
              style={{ height: "100vh" }}
              className="justify-center d-flex align-items-center"
            >
              <Loading />
            </div>
          ) : categoryData && categoryVouchers.length > 0 ? (
            <Row>
              <ToastContainer />
              <div className="mb-3 text-center col-lg-6">
                <img
                  className="mb-5 category-img"
                  src={categoryData.image}
                  alt="category"
                />
                <div className="voucher-video">
                  <Row>
                    {categoryVouchers.length > 2 ? (
                      <Slider {...settings}>
                        {categoryVouchers.map((vouchImg) => (
                          <Col xs={6} md={6} lg={4} key={vouchImg?.id}>
                            <img
                              src={vouchImg.productImage}
                              alt={vouchImg.productName}
                            />
                          </Col>
                        ))}
                      </Slider>
                    ) : (
                      <>
                        {categoryVouchers.map((vouchImg) => (
                          <>
                            <Col xs={6} md={6} lg={6} key={vouchImg?.id}>
                              <img
                                className="mb-3"
                                src={vouchImg.productImage}
                                alt={vouchImg.productName}
                              />
                            </Col>
                          </>
                        ))}
                      </>
                    )}
                  </Row>
                </div>
              </div>
              <Col lg={6}>
                <div className="arrow-heading">
                  <IoMdArrowRoundBack
                    onClick={() => navigate("/payorplay/all")}
                  />
                  <h1 className="voucher-name">
                    {categoryData.name_en || categoryData.name_ar}
                  </h1>
                </div>
                {/* vouchers name slider */}
                <Container>
                  <Row>
                    {categoryVouchers.length > 2 ? (
                      <Slider {...settings}>
                        {categoryVouchers.map((voucher, idx) => {
                          return (
                            <Col
                              xs={6}
                              md={4}
                              lg={4}
                              className="px-2 my-3"
                              key={voucher.id}
                            >
                              <div
                                className="voucher-box"
                                style={
                                  idx === index
                                    ? {
                                        border:
                                          "3px solid rgba(255, 242, 176, 1) !important",
                                      }
                                    : { border: "none" }
                                }
                                onClick={() => {
                                  setIndex(idx);
                                }}
                              >
                                <p
                                  style={
                                    idx === index
                                      ? { color: "rgba(255, 242, 176, 1)" }
                                      : { color: "white" }
                                  }
                                >
                                  {voucher.productName}
                                </p>
                              </div>
                            </Col>
                          );
                        })}
                      </Slider>
                    ) : (
                      categoryVouchers.map((voucher, idx) => {
                        return (
                          <>
                            <Col
                              xs={6}
                              md={4}
                              lg={4}
                              className="px-2 my-3"
                              key={voucher.id}
                            >
                              <div
                                className="voucher-box"
                                style={
                                  idx === index
                                    ? {
                                        border:
                                          "3px solid rgba(255, 242, 176, 1)",
                                      }
                                    : { border: "none" }
                                }
                                onClick={() => {
                                  setIndex(idx);
                                }}
                              >
                                <p
                                  style={
                                    idx === index
                                      ? { color: "rgba(255, 242, 176, 1)" }
                                      : { color: "white" }
                                  }
                                >
                                  {voucher.productName}
                                </p>
                              </div>
                            </Col>
                          </>
                        );
                      })
                    )}
                  </Row>
                </Container>
                {/* vouchers name slider */}

                {/* vouchers details */}
                <Row className="mt-2">
                  <Col lg={12}>
                    <div className="voucher-list">
                      <span className="list-title">{t("Price")} : </span>
                      <span className="ml-5 price">
                        <span style={{ color: "black" }}>
                          {inputValue > 0
                            ? categoryVouchers[index]?.sellPrice * inputValue
                            : categoryVouchers[index]?.sellPrice}
                        </span>
                        <span>{categoryVouchers[index]?.productCurrency}</span>
                      </span>
                    </div>
                    <div className="voucher-list">
                      <span className="list-title">{t("Quantity")} : </span>
                      <input
                        onChange={handleInputChangeQty}
                        value={inputValue}
                        type="number"
                        min={1}
                        max={9}
                        placeholder="Qty"
                        className="voucher-qty"
                        ref={inputRef}
                      />
                    </div>
                    <div className="voucher-list">
                      <span className="list-title">{t("Tokens Price")} :</span>
                      <span>
                        {inputValue > 0
                          ? categoryVouchers[index]?.token_price * inputValue
                          : categoryVouchers[index]?.token_price}
                      </span>
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={12} className="mt-3">
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <div className="add-cart">
                          <BiShoppingBag
                            onClick={() => {
                              if (token) {
                                if (
                                  !arr.includes(categoryVouchers[index].id) &&
                                  !categoryVouchers[index].has_cart
                                ) {
                                  addToCart(
                                    "cart",
                                    token,
                                    categoryVouchers[index].id,
                                    "voucher",
                                    inputValue
                                  );
                                  // success alert message
                                  notify("Added To Cart");
                                } else {
                                  notify1("Already Exist In Cart");
                                }
                              } else {
                                notify1("Please Sign In First");
                              }
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <div className="add-wishlist">
                          <AiOutlineHeart
                            onClick={() => {
                              if (token) {
                                addToWishlist(
                                  token,
                                  categoryVouchers[index].id,
                                  "voucher"
                                );
                              } else {
                                notify1("Please Sign In First");
                              }
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        <button
                          onClick={() => {
                            handleShow();
                          }}
                          className="voucher-btn"
                        >
                          {t("buy")}
                        </button>
                      </Col>
                    </Row>
                    <Modal
                      show={show}
                      onHide={handleClose}
                      keyboard={false}
                      className="buy-modal"
                    >
                      <Modal.Body className="voucher-modal">
                        <Card>
                          <Card.Img
                            variant="top"
                            className="h-auto"
                            src={categoryVouchers[index].productImage}
                          />
                          <Card.Body>
                            <Card.Title className="my-3 text-white">
                              {categoryVouchers[index].productName}
                            </Card.Title>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                <p className="price">
                                  {categoryVouchers[index].sellPrice *
                                    inputValue}{" "}
                                  {categoryVouchers[index].productCurrency}
                                </p>
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                <div className="qty">
                                  <p>
                                    <button
                                      style={
                                        inputValue <= 1
                                          ? { cursor: "not-allowed" }
                                          : { cursor: "pointer" }
                                      }
                                      onClick={() =>
                                        decreaseAmount(
                                          categoryVouchers[index].id,
                                          "voucher",
                                          inputValue
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                    <span style={{ margin: "0 3px" }}>
                                      {inputValue}
                                    </span>
                                    <button
                                      style={
                                        inputValue >= 8
                                          ? { cursor: "not-allowed" }
                                          : { cursor: "pointer" }
                                      }
                                      onClick={() =>
                                        increaseAmount(
                                          categoryVouchers[index].id,
                                          "voucher",
                                          inputValue
                                        )
                                      }
                                    >
                                      +
                                    </button>
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                        <SinglePayment
                          live={categoryVouchers[index]}
                          qty={inputValue}
                        />
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
                {/* vouchers details */}
              </Col>
            </Row>
          ) : (
            <div className="empty-voucher">
              <h2>{t("There Is No Vouchers For This Category....!")}</h2>
            </div>
          )}
          {categoryVouchers.length > 0 ? (
            <VoucherTabs
              categoryData={categoryData}
              categoryDataRedeem={categoryDataRedeem}
            />
          ) : (
            ""
          )}
          {/* voucher details tabs */}
        </div>
      </div>
    </>
  );
};

export default VoucherGame;
