import Modal from "react-bootstrap/Modal";
import modal_icon from "../../assets/modal_icon.png";
import "./modals.css";
import { Col, Row } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";
import { useTranslation } from "react-i18next";

function Modal1({ show, setShow, tournamentDetails }) {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            <MdOutlineClose
              className="modal-close-btn"
              onClick={() => setShow(false)}
            />
            <Row>
              <Col xs={3} md={3} lg={3}></Col>
              <Col xs={6} md={6} lg={6}>
                <img className="w-100" src={modal_icon} alt="icon" />
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="mb-3">{t("What is Tournament Rules?")}</h2>
          <p>{tournamentDetails.rules}</p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Modal1;
