import React from "react";
import { useEffect, useState } from "react";
import logo2 from "../../assets/dzo-logo.png";

import "./footer.css";
import logo from "../../assets/gamer_club_logo.png";
// import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import play_store from "../../assets/google_store.png";
// import google_store from "../../assets/app_Store.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  let  hostName = window.location.hostname;
  let isDzo= false;

  useEffect(() => {
   
    if(hostName =="dzo.gamerclub.live"){
      isDzo=true;
    }
    else{
      isDzo=false;
    }
  }, []);
  return (
    <div className="footer">
      <Container fluid>
        <Row>
          <Col className="p-0 mb-3 first-col" xs={12} md={5} lg={4}>
            <img src={ logo2 } alt="footer-logo" className="footer-logo" />
            <h2>
              <span>{t("Ready to level up")}</span>
            </h2>
            {/* <div className="playit-links">
              <Link
                to="https://play.google.com/store/apps/details?id=com.gt.pi"
                target="_blank"
              >
                <img src={play_store} alt="play_store" />
              </Link>
              <Link
                to="https://apps.apple.com/eg/app/playit-pi/id1555945981"
                target="_blank"
              >
                <img src={google_store} alt="app_store" />
              </Link>
            </div> */}
          </Col>
          <Col className="contact" xs={12} md={7} lg={5}>
            <h1 className="social-title">{t("contact us")}</h1>
{/*             <h2 className="contact-title">{t("phone")} : +201288084000</h2>
 */}            <h2 className="mb-4 contact-title">
              {t("email")} : support@gtmultiverse.com
            </h2>
          </Col>
          <Col className="contact" xs={12} md={12} lg={3}>
            <h1 className="social-title">{t("Stay Up To Date")}</h1>
            <input
              type="text"
              placeholder={t("Enter Your E_mail")}
              className="inp"
            />
            {/* <div className="footer-icons">
              <Link
                to="https://www.tiktok.com/@playitapp?_t=8kTzxoT7EoQ&_r=1"
                target="_blank"
              >
                <FaTiktok />
              </Link>
              <Link
                to="https://www.instagram.com/playitapp_?igsh=eHhzYnU3cGcycTU0"
                target="_blank"
              >
                <FaInstagram />
              </Link>
              <Link
                to="https://www.facebook.com/share/zyZ22kEBcDij5ehR/?mibextid=eQY6cl"
                target="_blank"
              >
                <FaFacebookF />
              </Link>
            </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
