import React, { useEffect, useState } from "react";
import "./profile.css";
import ProfileTabs from "../../Components/ProfileTabs/ProfileTabs";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/NavbarComp";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading/Loading";
import { getUserDetails } from "../../Utilies/gamerClubApi";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };
  // store user details in state
  const [data, setData] = useState([]);
  // get user token
  const token = localStorage.getItem("token");
  async function userDetails(token) {
    try {
      let result = await getUserDetails(token);
      setData(result);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const dateDiffMs = Date.now() - birthDate.getTime();
    const ageYear = new Date(dateDiffMs);
    return Math.abs(ageYear.getUTCFullYear() - 1970);
  };
  const dateOfBirth = calculateAge(data.dob);

  useEffect(() => {
    userDetails(token);
  }, [token]);

  return (
    <>
      <Navbar title={t("profile")} />
      {isLoading ? (
        <div
          style={{ height: "100vh" }}
          className="justify-center d-flex align-items-center"
        >
          <Loading />
        </div>
      ) : (
        <div className="profile" style={{ paddingTop: "150px" }}>
          <Container fluid>
            <SideMenuBar sideBar={sideBar} showSideBar={showSideBar} />
          </Container>
          <Container fluid>
            {/* user-profile-data */}
            <Row>
              <Col xs={12} md={4} lg={3} className="user-pic">
                <img
                  src={`http://api.gamerclub.live${data?.avatar}`}
                  alt="user-img"
                />
              </Col>
              <Col xs={12} md={8} lg={0} className="mt-2 ml-2 user-info">
                <h1>{data?.first_name}</h1>
                <Row>
                  <Col xs={6} lg={4}>
                    <ul className="user-list">
                      <li>{t("ID")}</li>
                      <li>{t("Email")}</li>
                      <li>{t("Phone Number")}</li>
                      <li>{t("Gender")}</li>
                      <li>{t("Age")}</li>
                      <button
                        className="Btn"
                        onClick={() => navigate("/settings")}
                      >
                        {t("Edit Profile")}
                        <svg
                          style={
                            localStorage.getItem("i18nextLng") === "ar"
                              ? { left: "10px" }
                              : { right: "0%" }
                          }
                          className="svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" />
                        </svg>
                      </button>
                    </ul>
                  </Col>
                  <Col xs={6} lg={8}>
                    <ul className="user-data-1">
                      <li>{data?.id}</li>
                      <li>{data?.email ? data?.email : "N/A"}</li>
                      <li>{data?.phone ? data?.phone : "N/A"}</li>
                      <li>
                        {data?.gender
                          ? data?.gender === 1
                            ? "Male"
                            : "Female"
                          : "N/A"}
                      </li>
                      <li>{data?.dob ? dateOfBirth : "N/A"}</li>

                      <button
                        className="cssbuttons-io-button"
                        onClick={() => navigate("/shopping")}
                      >
                        {t("view my cart")}
                        <div className="icon">
                          <svg
                            height={24}
                            width={24}
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path
                              d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </button>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* taps */}
            <ProfileTabs />
          </Container>
        </div>
      )}
    </>
  );
};

export default Profile;
