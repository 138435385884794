import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ShoppingCart } from "lucide-react";
import { getCategoryDetails } from "../../Utilies/api";
import { Col, Modal, Row } from "react-bootstrap";
import SinglePayment from "../SinglePayment/SinglePayment";
import { notify, notify1 } from "../ToastComponent";
import { useTranslation } from "react-i18next";
import { postCartItem } from "../../Utilies/gamerClubApi";

const PayOrPlayItem = ({ category }) => {
  const { t } = useTranslation();
  // show payment modal
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [showId, setShowId] = useState(0);

  const handleClose = () => setShow(false);

  const handleShow = (idx) => {
    setShow(true);
    setShowId(idx);
  };
  // get user id
  let userId = JSON.parse(localStorage.getItem("user_id"));
  // get user token
  const userToken = localStorage.getItem("token");
  // store category vouchers inside state
  const [categoryVouchers, setCategoryVouchers] = useState([]);
  // store select options
  const [selectOption, setSelectOption] = useState(0);
  const { i18n } = useTranslation();
  // show category vouchers in select dropdown menu in category card
  const getDetails = async (id, token, langType) => {
    let translations = await getCategoryDetails(id, token, langType);
    i18n.addResourceBundle(i18n.language, "translation", translations);
    setCategoryVouchers(translations.data.vouchers);
  };

  // show hidden category content
  const [showContent, setShowContent] = useState(false);
  const showCtegoryContent = () => {
    setShowContent(!showContent);
  };
  // convert price to integer number
  const price = categoryVouchers[selectOption]?.sellPrice;
  let enprice = Intl.NumberFormat("ar-AR").format(price);

  const [arr, setArr] = useState([]);
  // add to cart
  async function addToCart(
    sectionType,
    token,
    productId,
    cartItemType,
    voucherQty
  ) {
    setArr([...arr, productId]);
    let formData = {
      items: [
        {
          object_id: productId,
          qty: voucherQty,
          type: cartItemType,
        },
      ],
    };
    await postCartItem(formData, sectionType, token);
  }

  useEffect(() => {
    getDetails(category.id, userToken, i18n.language);
  }, [category.id, userToken, i18n.language]);

  return (
    <div className="gold-card">
      <div className="pull">
        <div className="pull-1"></div>
        <div className="pull-2"></div>
      </div>
      <img src={category.image} alt="category" />
      {categoryVouchers.length > 0 ? (
        <div className="vouch-details">
          <div className="lives-price">
            <Link to={`/voucher/${category.id}`} className="lives-number">
              {category.name_en || category.name_ar}
            </Link>
            {categoryVouchers.length <= 0 ? (
              <span className="voucher-price outofstock">Out Of Stock</span>
            ) : (
              <span className="voucher-price">
                {enprice} {category.voucher_currency}
              </span>
            )}
          </div>
          <p
            style={{
              overflowY: showContent ? "scroll" : "hidden",
              height: showContent ? "300px" : "300px",
            }}
          >
            {category.description_en || category.description_ar}
          </p>

          <div className="points">
            {category.description_en !== "" ? (
              <span onClick={() => showCtegoryContent()}>
                {!showContent ? t("Read More...") : t("Read Less...")}
              </span>
            ) : (
              ""
            )}
            {categoryVouchers.length > 0 ? (
              <select
                onChange={(e) => {
                  setSelectOption(e.target.value);
                  setIndex(e.target.value);
                }}
                className="form-select"
              >
                <option disabled={true}>Select Package</option>
                {categoryVouchers.map((cat, idx) => (
                  <option key={cat.id} value={idx}>
                    {cat.productName}
                  </option>
                ))}
              </select>
            ) : (
              ""
            )}
          </div>
          {categoryVouchers.length > 0 ? (
            <div className="buy">
              <button
                className="btn-buy"
                onClick={() => {
                  if (userToken) {
                    if (
                      !arr.includes(categoryVouchers[index].id) &&
                      !categoryVouchers[index].has_cart
                    ) {
                      addToCart(
                        "cart",
                        userToken,
                        categoryVouchers[index].id,
                        "voucher",
                        1
                      );
                      // success alert message
                      notify("Added To Cart");
                    } else {
                      notify1("Already Exist In Cart");
                    }
                  } else {
                    notify1("Please Sign In First");
                  }
                }}
              >
                {t("add to cart")} <ShoppingCart />
              </button>
              <button
                onClick={() => {
                  handleShow(index);
                }}
                className="btn-buy"
              >
                {t("buy now")}
              </button>
              <Modal
                show={showId === index && show}
                onHide={handleClose}
                keyboard={false}
              >
                <Modal.Body className="voucher-modal">
                  <Row>
                    <Col xs={12} md={6} lg={6}>
                      <img
                        className="mb-3 w-100"
                        style={{
                          backgroundColor: "white",
                          padding: "10px",
                          borderRadius: "20px",
                        }}
                        src={category.image}
                        alt="itemship"
                      />
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <p className="member-title">
                        {category.name_en || category.name_ar}
                      </p>
                      <p className="member-description">
                        {category?.description_en?.substring(0, 200) ||
                          category?.description_ar?.substring(0, 200)}
                        ...
                      </p>
                      <p className="mb-3 member-price">
                        <span>{enprice}</span>
                        <span>{category.voucher_currency}</span>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12} className="payment-tabs">
                      <SinglePayment live={categoryVouchers[index]} qty={"1"} />
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PayOrPlayItem;
