import React, { useState } from "react";
import "./settings.css";
import edit from "../../assets/settings/aside/Edit.png";
import notify from "../../assets/settings/aside/Notifications.png";
import help from "../../assets/settings/aside/Help.png";
import { Col, Container, Form, Row } from "react-bootstrap";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import UserFormData from "../../Components/UserFormData/UserFormData";
import Navbar from "../../Components/Navbar/NavbarComp";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(1);
  const updateToggle = (id) => {
    setToggle(id);
  };
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };

  return (
    <>
      <Navbar title={t("settings")} />
      <div className="settings" style={{ paddingTop: "150px" }}>
        <Container>
          <SideMenuBar sideBar={sideBar} showSideBar={showSideBar} />
          <div className="setting-content">
            {/* aside */}
            <Row>
              <Col xs={12} md={4} lg={4}>
                <div className="setting-side">
                  <div
                    onClick={() => updateToggle(1)}
                    className={
                      toggle === 1 ? "setting-icon activ" : "setting-icon"
                    }
                  >
                    <div className="row">
                      <div className="col-md-2">
                        <img src={edit} alt="" />
                      </div>
                      <div className="col-md-10">
                        <span>{t("Edit profile")}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => updateToggle(2)}
                    className={
                      toggle === 2 ? "setting-icon activ" : "setting-icon"
                    }
                  >
                    <div className="row">
                      <div className="col-md-2">
                        <img src={notify} alt="" />
                      </div>
                      <div className="col-md-10">
                        <span>{t("Notifications")}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => updateToggle(3)}
                    className={
                      toggle === 3 ? "setting-icon activ" : "setting-icon"
                    }
                  >
                    <div className="row">
                      <div className="col-md-2">
                        <img src={help} alt="" />
                      </div>
                      <div className="col-md-10">
                        <span>{t("terms and conditions")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              {/* settings button */}

              <Col xs={12} md={8} lg={8}>
                {/* user edit form */}
                <div
                  className={
                    toggle === 1
                      ? "show-content user-edit mb-5 text-center"
                      : "hide-content"
                  }
                >
                  {/* user edit form */}
                  <UserFormData />
                </div>
                {/* user edit form */}

                {/* notification */}
                <div
                  className={
                    toggle === 2
                      ? "show-content user-edit mb-5"
                      : "hide-content"
                  }
                >
                  <div className="notification">
                    <span>{t("Notifications")}</span>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                    />
                  </div>
                </div>
                {/* notification */}

                {/* licence */}
                <div
                  className={
                    toggle === 3
                      ? "show-content user-edit mb-5"
                      : "hide-content"
                  }
                >
                  <p className="mb-3 terms">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using ' Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                  <div className="license">
                    <Form.Check aria-label="option 1" checked />
                    <span>{t("agree with terms and conditions")}</span>
                  </div>
                </div>
                {/* licence */}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Settings;
