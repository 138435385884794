import SignupPage from "../Pages/SignUp/SignUp";
import EtisalatSignUp from "../Pages/SignUp/EtisalatSignUp";
// import IraqSignup from "../Pages/SignUp/IraqSignUp";
const hostNamesInfo = [{
  hostName: "dzo.gamerclub.live",
  theme: "iraq",
},
{
  hostName: "localhost",
  theme: "iraq",
  // theme: "iraq",
}
]; 
export const getHostComponentByRoute = (route) => {
  const hostname = document.location.host;
  if (route === "signup") {
    switch (hostname) {
      // Algeria
      case "dzo.gamerclub.live":
        return <SignupPage />;
      // case "ziq.gamerclub.live":
      //   return <IraqSignup />;
        
        case "etisalatuae.gamerclub.live":
          return <EtisalatSignUp />;
      default:
        // return <IraqSignup />;
        return <EtisalatSignUp />;
    }
  }
  // else   if (route === "signIn") {
  //   switch (hostname) {
  //     // Algeria
  //     case "dzo.gamerclub.live":
  //       return <SignIn />;
  //     // case "ziq.gamerclub.live":
  //     //   return <IraqSignup />;
        
  //       case "etisalatuae.gamerclub.live":
  //         return <EtisalatSignUp />;
  //     default:
  //       // return <IraqSignup />;
  //       return <EtisalatSignUp />;
  //   }
  // }
};
export const getThemeByHostName = hostName => {
  const hostNameInfo = hostNamesInfo.find(i => i.hostName === hostName);
  if (hostNameInfo) {
   
     //console.log('theme', hostNameInfo.theme, hostNameInfo.hostName)
      return hostNameInfo.theme;
  }
  return false;
};
