import React, { useState, useEffect } from "react";
import axios from "axios";
import "./OtpCode.css";
import OTPInput from "react-otp-input";
import otp_side from "../../assets/forms/side_otp_img.png";
import side_img from "../../assets/forms/side_signup_img.png";
import { Link, useNavigate } from "react-router-dom";
import { saveTracker, setAgencyData } from "../../Utilies/gamerClubApi";
import { Col, Row } from "react-bootstrap";
import FormsAside from "../../Components/FormsAside/FormsAside";
import { notify } from "../../Components/ToastComponent";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import AuthLanguageSelector from "./LanguageChange";

const EtisalatSignUp = () => {
  localStorage.setItem("i18nextLng", "ar");
  const { t } = useTranslation();
  const navigate = useNavigate();
  // lodaing state
  const [lodaing, setLodaing] = useState(true);
  // errors messages state
  const [errorMsg, setErrorMsg] = useState("");
  const [otp, setOtp] = useState("");
  const [transactionidentify, setTransactionidentify] = useState("");
  const [transactionid, setTransactionid] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [showSub, setshowSub] = useState(false);
  const [showPendding, setshowPendding] = useState(true);
  const [phonenumber, setphonenumber] = useState("");
  const [sub_id, setsub_id] = useState("");
  const [errorMesg, setErrorMesg] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [errorresverify, seterrorresverify] = useState("");
  const [activeData, setActiveData] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);
  const [showverif, setShowverif] = useState(false);

  const [agree, setagree] = useState(false);

  // user state
  const [formData, setFormData] = useState({
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
  });

  useEffect(() => {
    localStorage.setItem("i18nextLng", "ar");

    //yousry lang
    //console.log("yousry lang");
    // MCPShield();
    const params = queryString.parse(window.location.search);
    if (params.lang) {
      //console.log("lang",params.lang);
      localStorage.setItem("i18nextLng", params.lang);
      if (localStorage.getItem("i18nextLng") != params.lang) {
        localStorage.setItem("i18nextLng", params.lang);
        window.location.reload();
      }
      //window.location.reload();
      //localLang.checklocalLanguage();
    }
    // setAgencyData(params);
    if (params.camp) {
      setagree(true);
    }
    if (params.msisdn == undefined) {
      setshowSub(false);

      //  getRedirectURL(params);
      console.log(params);
    } else {
      var phone = params.msisdn;
      if (phone !== null && phone !== "") {
        //    fetchDataWithMsidn(phone);
      } else {
        setshowPendding(false);
      }
    }
  }, []);

  const tracker = async (GMSISDN) => {
    var datag = {};
    if (localStorage.getItem("tracker")) {
      var op_id = "zFGLFvkj8L";
      datag = {
        sendCamp: 1,
        company: localStorage.getItem("trackerCompany"),
        key: localStorage.getItem("tracker"),
        operator_id: 3,
        msisdn: GMSISDN,
      };
      //console.log(datag)
    }
    await saveTracker(datag);
  };

  function manipulateMsisdn(msisdn) {
    msisdn = msisdn.replace("+", "");
    if (startsWith(msisdn, "0")) {
      msisdn = msisdn.substring(1);
    } else if (startsWith(msisdn, "00")) {
      msisdn = msisdn.substring(2);
    }
    if (!startsWith(msisdn, "213")) {
      msisdn = "213" + msisdn;
    }
    return msisdn.trim();
  }

  function startsWith(str, prefix) {
    return str.indexOf(prefix) === 0;
  }

  // get form inputs values
  function getFormData({ target }) {
    //console.log(target.value.replace(/[^0-9]/g, ""));
    setActiveData(target.value.replace(/[^0-9]/g, ""));
    //    setphonenumber(target.value.replace(/[^0-9]/g, ""));
    setshowSub(false);
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  }

  const Subscribe = async (e) => {
    //console.log(e.target.phone_number.value);
    setActiveData(e.target.phone.value);
    const phone_number_val = e.target.phone.value;
    //console.log(activeData);
    e.preventDefault();

    if (phone_number_val) {
      var phon_n = phone_number_val.toString();
      console.log(manipulateMsisdn(phon_n));
      const url = `https://vas1.grand-hub.com/api/gamerClub/etisalat-uae-d/subscribe`;
      setLodaing(false);
      await axios
        .post(url, { phone: phon_n })
        .then((res) => {
          if (res.data.status === true) {
            setShowverif(true);
          } else {
            if (res.data.msg != null) {
              setErrorMsg(res.data.msg);
              setLodaing(true);
            } else {
              setLodaing(true);

              setErrorMsg(
                t(
                  "Something went wrong! Please make sure you have the correct number inserted or try again later.-ar"
                )
              );
            }
            // setShowverif(true)
          }
          setLodaing(true);
        })
        .catch(function (error) {
          setErrorMesg(
            setErrorMsg(
              t(
                "Something went wrong! Please make sure you have the correct number inserted or try again later.-ar"
              )
            )
          );
          setShowverif(false);
          setLodaing(false);
        })
        .finally(function () {
          //console.log('complete');
        });
    }
  };

  const verify = async (e) => {
    e.preventDefault();
    if (otp) {
      var phon_n = activeData;
      setLodaing(false);

      axios
        .post(
          `https://vas1.grand-hub.com/api/gamerClub/etisalat-uae-d/verify`,
          {
            phone: phon_n,
            otp: otp,
          }
        )

        .then((res) => {
          //console.log(res);
          setLodaing(true);
          if (res.data.status == true) {
            tracker(phon_n);
            gtag_report_conversion();
            notify("OTP Code Is Correct");
            setTimeout(() => {
              navigate("/success", { replace: true });
            }, 2500);
          } else {
            setLodaing(false);

            // tracker(phon_n);
            if (res.data.msg != null) {
              setErrorMsg(res.data.msg);
              //setVerfiErrorMesg(true);
            } else {
              setErrorMsg(
                t(
                  "Something went wrong! Please make sure you have the correct verification code inserted or try again later.-ar"
                )
              );
              //----setVerfiErrorMesg(true);
            }
            // setVerfiErrorMesg(true);
            // seterrorresverify(res.data.msg);
          }
          setLodaing(true);
        })
        .catch(function (error) {
          //console.log(error);
          //    setVerfiErrorMesg(true);
          // seterrorresverify(res.data.msg);
        })
        .finally(function () {
          //console.log('complete');
        });
    }
  };

  // send data to api
  function sendData(e) {
    e.preventDefault();
    if (showSub) {
      // subscribeHe(e);
    } else {
      Subscribe(e);
    }
    // e.preventDefault();
    // subscribeHe(e);
    // try {
    //   Subscribe(e);
    //   // setLodaing(false);
    //   // if(showSub){
    //   //   subscribeHe();
    //   // }
    //   // else{
    //   //   //Subscribe()
    //   //   setShowverif(true);
    //   // }

    //   /*
    //   await signupForm(formData);
    //   setErrorMsg("");
    //   localStorage.setItem("userPhone", formData.phone);
    //   notify("SignUp Successfully");
    //   */

    // } catch (error) {
    //   console.log(error);
    //   setErrorMsg(error.response?.data?.error);
    //   setLodaing(true);
    // }
  }
  const verifyOtp = async () => {
    try {
      setLodaing(false);
      // let formData = {
      //   phone: phone_number,
      //   otp: otp,
      // };
      // let result = await linkOtpCode(formData);
      // console.log(result);
      // // success alert message
      notify("OTP Code Is Correct");
      // localStorage.setItem("user_id", result?.user?.id);
      // // store token to local storage
      // localStorage.setItem("token", result?.access_token);
      // localStorage.setItem("userImage", JSON.stringify(result?.user?.avatar));
      notify("OTP Code Is Correct");
      setTimeout(() => {
        navigate("/success", { replace: true });
      }, 2500);
    } catch (error) {
      if (error) {
        //notify1(error?.response?.data?.message);
      }
      setLodaing(false);
      setOtp("");
    }
  };
  function gtag_report_conversion() {
    let tracker_value = localStorage.getItem("tracker");
    if (localStorage.getItem(tracker_value) != "1") {
      window.gtag("event", "conversion", {
        send_to: "AW-619421651/C_ScCIHe_qcZENO_rqcC",
      });
      localStorage.setItem(tracker_value, "1");
    }
    return false;
  }

  return (
    <div className="signUp">
      <ToastContainer />
      <Row
        className="mx-0"
        dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
      >
        <FormsAside side_img={side_img} />
        <Col xs={12} md={7} lg={8} xl={8} className="form">
          <Row className="h-20 page-content">
            <Col className="m-auto" xs={12} md={8} lg={8}>
              {/* <h1>{t("create account-ar")}</h1> */}

              {/* <div className="google"> */}
              {/* <img src={google_img} alt="google" className="mx-2" />
                {t("sign up with Google")} */}
              {/* </div> */}
              {/* <span>{t("-OR-")}</span> */}
              {errorMsg ? (
                <div className="text-left alert alert-danger">
                  <p>{errorMsg}</p>
                </div>
              ) : (
                ""
              )}

              {!showverif ? (
                <form className="signUp-form" onSubmit={sendData}>
                  {" "}
                  <div className="ms-auto lang">
                    <AuthLanguageSelector />
                  </div>
                  <p>
                    {t(
                      "Join an online platform for gamers to compete in tournaments and track their performance through leaderboards. It offers a space for both casual and competitive players across various games."
                    )}
                  </p>
                  <h3>
                    {t("Enter your Etisalat Mobile Number to receive OTP")}
                  </h3>
                  {/* <div className="gap-5 d-flex justify-content-between align-items-center">
                  <input
                    onChange={getFormData}
                    name="first_name"
                    type="text"
                    placeholder={t("first_name")}
                    required
                  />
                  <input
                    onChange={getFormData}
                    name="last_name"
                    type="text"
                    placeholder={t("last_name")}
                    required
                  />
                </div> */}
                  {/* <input
                  onChange={getFormData}
                  name="email"
                  type="email"
                  placeholder={t("email")}
                  required
                /> */}
                  <div className="row ">
                    <div className="col-9">
                      <input
                        dir={
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "rtl"
                            : "ltr"
                        }
                        onChange={getFormData}
                        value={activeData}
                        name="phone"
                        className="phone"
                        type="tel"
                        placeholder={t("Phone-Ar")}
                        required
                      />
                    </div>
                    <div className="col-3">
                      <select>
                        <option value="+971">+971 </option>
                      </select>
                    </div>
                  </div>
                  <div className="submit-info">
                    <button
                      //   disabled={agree == true ? "" : "disabled"}
                      type="submit"
                      id="signupbut"
                      className="btn-submit"
                    >
                      {lodaing ? (
                        t("Create Account-Ar")
                      ) : (
                        <i className="fas fa-spinner fa-spin"></i>
                      )}
                    </button>
                    <h4>
                      {" "}
                      {t("Free for 24 hours then AED 2 per day (excl. VAT)")}
                    </h4>
                    <div className="d-flex align-items-center justify-content-center ">
                      {/* <h6 className="form-check-label mb-0 fw-bold">قبول الشروط والأحكام</h6>
                      <input
                        type="checkbox"
                        className="form-check-input mt-0 me-2 check-box "
                        
                        onChange={(e) => {
                          setagree(!agree);
                        }}
                        checked={agree}
                      /> */}
                    </div>
                    {/* 
                    <div>
                      <p className="text-center fw-medium mb-0 mt-4">
                      ملحوظة: سوف تكون مشتركًا في Ludo برقمك
                      الهاتف الحالي
                      </p>
                      <p className="text-center fw-medium mb-0">
                      رسوم الاشتراك هي 35.00 دج يوميا
                      </p>
                      <p className="text-center fw-medium mb-0">
                      لمزيد من المعلومات، يرجى الاتصال بنا على
                      support@grandmstg.com{" "}
                      </p>
                    </div> */}
                    <h5>{t("TERMS AND CONDITIONS")}</h5>
                    <p>
                      {t(
                        "By clicking on subscribe, you agree to the below terms and conditions"
                      )}
                    </p>
                    <ul>
                      <li>
                        {t(
                          "You will start the paid subscription after the free period automatically"
                        )}
                      </li>
                      <li>
                        {t(
                          "you can cancel your subscription at any time by sending Unsub GamerClub to 4273"
                        )}
                      </li>
                      <li>
                        {t(
                          "To get support, please contact support@grandmstg.com"
                        )}
                      </li>
                      <li>
                        {t("The free trial is valid only for new subscribers")}
                      </li>
                      <li>
                        {t(
                          "Enjoy Your Free trial for 24 hours \n Please make sure that your browser is not using any 3rd-party blocking technologies and you have a healthy internet connection for swift access to the content"
                        )}
                      </li>
                      <li>
                        {t("To view the")}{" "}
                        <a href="terms" target="_blank">
                          {t("Terms And Conditions")}
                        </a>{" "}
                      </li>
                      <li>
                        {t("To view the")}{" "}
                        <a href="privacy" target="_blank">
                          {t("privacy policy")}
                        </a>{" "}
                      </li>
                    </ul>
                    <h3>
                      {t("Already Have An Account?-ar")}{" "}
                      <Link to="/signin">{t("signin-ar")}</Link>
                    </h3>
                  </div>
                  <div className="ms-auto">
                    <button
                      className="exit"
                      onClick={() => {
                        navigate(`/`);
                      }}
                    >
                      {t("exit ")}
                    </button>{" "}
                  </div>
                </form>
              ) : null}

              {showverif ? (
                <OTPInput
                  inputType="number"
                  containerStyle={{
                    justifyContent: "center",
                    direction: "ltr",
                  }}
                  inputStyle={{
                    width: "40px",
                    height: "50px",
                    justifyContent: "space-between",
                    border: "2px solid black",
                    direction: "ltr",
                  }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input required {...props} />}
                  shouldAutoFocus={true}
                />
              ) : null}

              {showverif ? (
                <div className="submit-info">
                  <button
                    onClick={(e) => verify(e)}
                    className="btn-submit w-100"
                  >
                    {lodaing ? (
                      t("Verify Otp") //اشترك
                    ) : (
                      <i className="fas fa-spinner fa-spin"></i>
                    )}
                  </button>
                </div>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default EtisalatSignUp;
