import "./PrivacyTerms.css";

const Privacy = () => {
  return (
    <div>
      {" "}
      <body className="privacy">
        <h1>Privacy Policy</h1>
        <p>
          <strong>Effective Date: 3/29/2024</strong>
        </p>

        <h2>Introduction</h2>
        <p>
          Gamer Club ("we," "us," or "our") respects the privacy of our users
          ("you" or "your"). This Privacy Policy describes how we collect, use,
          disclose, and secure your personal information when you use the
          Service_name (the "Service").
        </p>

        <h2>Information We Collect</h2>
        <p>
          We collect several different types of information for various purposes
          to improve our Service and to provide you with the best possible
          service.
        </p>

        <h3>Personal Information:</h3>
        <p>
          When you use the Service, we may collect certain personal information
          that can be used to identify you, such as your name, email address,
          and job title. This information is typically collected when you
          register for the Service or use certain features.
        </p>

        <h3>Usage Data:</h3>
        <p>
          We may also collect usage data to improve the Service and personalize
          your experience.
        </p>

        <h3>Device Information:</h3>
        <p>
          We may collect information about the device you use to access the
          Service, such as the device type, operating system, IP address, and
          unique device identifier. This information is used for troubleshooting
          purposes and to improve the security of the Service.
        </p>

        <h2>How We Use Your Information</h2>
        <p>
          We use the information we collect for various purposes, including:
        </p>
        <ul>
          <li>To provide and operate the Service</li>
          <li>To process your requests</li>
          <li>To personalize your experience with the Service</li>
          <li>
            To communicate with you about the Service, including updates,
            security alerts, and support messages
          </li>
          <li>To track and analyze usage data to improve the Service</li>
          <li>To comply with legal and regulatory requirements</li>
        </ul>

        <h2>Sharing Your Information</h2>
        <p>
          We may share your information with third-party service providers who
          help us operate the Service and provide related services. These
          service providers are obligated to protect your information and use it
          only for the purposes we have specified.
        </p>
        <p>
          We may also disclose your information if required by law or to protect
          the rights, property, or safety of ourselves or others.
        </p>

        <h2>Data Security</h2>
        <p>
          We take reasonable steps to secure your information from unauthorized
          access, disclosure, alteration, or destruction. However, no internet
          transmission or electronic storage is completely secure. Therefore, we
          cannot guarantee the absolute security of your information.
        </p>

        <h2>Your Choices</h2>
        <p>You have certain choices regarding your information:</p>
        <ul>
          <li>
            You can access and update your personal information through the
            Service settings.
          </li>
          <li>
            You can choose to opt-out of receiving marketing communications from
            us.
          </li>
          <li>
            You can delete your account at any time from the service settings.
          </li>
        </ul>

        <h2>Children's Privacy</h2>
        <p>
          Our Service is not directed to children under the age of 13. We do not
          knowingly collect personal information from children under 13. If you
          are a parent or guardian and you believe your child has provided us
          with personal information, please contact us. We will take steps to
          remove the information from our servers.
        </p>

        <h2>Changes to this Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on the Service.
        </p>
      </body>
    </div>
  );
};
export default Privacy;
