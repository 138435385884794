import React, { useEffect, useState } from "react";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import star_empty from "../../assets/html5GamesPage/star_empty.png";
import star_fill from "../../assets/html5GamesPage/star_fill.png";
import "./html5Games.css";

// import function to register Swiper custom elements
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { register } from "swiper/element/bundle";
import AndroidItem from "../../Components/AndroidItem/AndroidItem";
import { searchEngine } from "../../Utilies/api";
import SearchInput from "../../Components/SearchInput/SearchInput";
import Navbar from "../../Components/Navbar/NavbarComp";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading/Loading";
import { notify1 } from "../../Components/ToastComponent";
import {
  getGamesCategoriesApi,
  getGamesListApi,
} from "../../Utilies/gamerClubApi";
register();

const Html5Games = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };

  // store html5 games in state
  // const [sliderGames, setSliderGames] = useState();
  // const [topGames, setTopGames] = useState([]);
  const [index, setIndex] = useState("arcade");
  const [gamesCategories, setGamesCategories] = useState([]);
  const [gamesList, setGamesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // search functionality
  const [queryInput, setQueryInput] = useState("");
  // get user token
  const token = localStorage.getItem("token");
  // const getAllGamesFun = async (userId) => {
  //   try {
  //     let { data } = await getHtmlGames(userId);
  //     if (data) {
  //       setSliderGames(data[index]);
  //       setTopGames(Object.values(data));
  //       setcategoryGames(Object.keys(data));
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //   }
  // };
  const getGamesCategories = async () => {
    try {
      const response = await getGamesCategoriesApi("select-html");
      setGamesCategories(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getGamesList = async (cat_name, search) => {
    try {
      const response = await getGamesListApi("html", cat_name, search);
      setGamesList(response.items);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getGamesCategories();
    getGamesList(index, queryInput);
  }, [index, isLoading, queryInput]);

  return (
    <>
      <Navbar title={t("html 5 games")} />
      <div className="html5Games" style={{ paddingTop: "125px" }}>
        <ToastContainer />
        <div className="container">
          <SideMenuBar sideBar={sideBar} showSideBar={showSideBar} />
        </div>
        {/* swipper js content */}
        <Container fluid>
          <swiper-container navigation="true" pagination="false">
            {gamesList?.map((game) => (
              <swiper-slide key={game.id}>
                <div
                  className="mx-2 swipper-slider"
                  style={{ backgroundImage: `url(${game?.photo})` }}
                >
                  <Row className="h-100">
                    <Col
                      xs={12}
                      md={12}
                      lg={12}
                      className="swiper-content"
                      style={{ background: "rgba(0, 0, 0, 0.3)" }}
                    >
                      <div className="stars">
                        <img src={star_fill} alt="star" />
                        <img src={star_fill} alt="star" />
                        <img src={star_fill} alt="star" />
                        <img src={star_fill} alt="star" />
                        <img src={star_empty} alt="star" />
                      </div>
                      <h1>{game.name}</h1>
                      <p style={{ width: "70%" }}>
                        {game?.description?.slice(0, 150).split(" ").join(" ")}
                        ....
                      </p>
                      <button
                        onClick={() => {
                          if (token) {
                            navigate(`/play/${game.id}`);
                          } else {
                            notify1("Please Signin First");
                          }
                        }}
                        className="btn-play"
                      >
                        {t("play now")}
                      </button>
                    </Col>
                  </Row>
                </div>
              </swiper-slide>
            ))}
          </swiper-container>
        </Container>
        {/* html5games content */}
        <Container fluid>
          <div className="mx-2 games-heading">
            <h1>{t("explore")}</h1>
            <div className="games-tabs">
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <SearchInput
                    queryInput={queryInput}
                    setQueryInput={setQueryInput}
                    searchPlaceholder={t("search...")}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Container>

        {/* html5Games items */}
        <Container fluid>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="mt-4 games-items">
              <div className="categories-menu">
                {gamesCategories.map((category, idx) => (
                  <h1
                    className={`category-name ${
                      category === index ? "active" : ""
                    }`}
                    key={idx}
                    onClick={() => {
                      setIsLoading(!isLoading);
                      getGamesList(category);
                      setIndex(category);
                    }}
                  >
                    {category === "undefined" ? "Other" : category}
                  </h1>
                ))}
              </div>
              <Row className="mb-5">
                {gamesList?.length > 0 ? (
                  gamesList.map((gameItem) => {
                    return (
                      <Col
                        xs={6}
                        md={4}
                        lg={3}
                        xl={3}
                        xxl={2}
                        key={gameItem.id}
                        className="mb-3"
                      >
                        <AndroidItem
                          android_img={gameItem.photo}
                          game_name={gameItem.name}
                          android_btn={t("play now")}
                          gameItemId={gameItem.id}
                          imgNavigate={"htmlGameDetails"}
                          btnNavigate={"play"}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <p className="empty-games">{t("no games found")}</p>
                )}
              </Row>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default Html5Games;
