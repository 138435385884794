import React, { useEffect, useState } from "react";
import "./TheWar.css";
import side_avatar from "../../assets/theWar/side_avatar.png";
import person from "../../assets/theWar/person.png";
import star from "../../assets/theWar/star.png";
import crown from "../../assets/theWar/crown.png";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import Modal1 from "../../Components/modals/Modal1";
import Modal3 from "../../Components/modals/Modal3";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import CountDown from "../../Components/CountDown/CountDown";
import { AiFillCaretDown } from "react-icons/ai";
import { notify, notify1 } from "../../Components/ToastComponent";
import { ToastContainer } from "react-toastify";
import Navbar from "../../Components/Navbar/NavbarComp";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading/Loading";
import {
  checkTournamentJoin,
  getTournamentDetails,
  getTournamentLeaderBoards,
  leaveTournament,
} from "../../Utilies/gamerClubApi";

const TheWar = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  let { id } = useParams();

  const navigate = useNavigate();

  // show modal1
  const [show, setShow] = useState(false);

  // show modal3
  const [showModal, setShowModal] = useState(false);

  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };

  // get user token
  const token = localStorage.getItem("token");
  // tournament details
  const [tournamentboard, setTournamentLeaderboard] = useState([]);
  const [tournamentDetails, setTournamentDetails] = useState([]);
  const [isJoined, setIsJoined] = useState("");

  async function tournDetails(tournId, token) {
    try {
      let result = await getTournamentDetails(tournId, token);
      setTournamentDetails(result);
      setIsJoined(result.is_joined);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }
  async function tournLeaserBoard(tournId, token) {
    try {
      const result = await getTournamentLeaderBoards(tournId, token);
      setTournamentLeaderboard(result);
    } catch (error) {}
  }

  //  join tournament
  async function JoinTournament(tournId, token) {
    try {
      let result = await checkTournamentJoin(tournId, token);
      notify(result.message);
      setIsJoined(result.message);
      setIsLoading(false);
    } catch (error) {
      notify1(error.response.data.message);
      setIsLoading(false);
    }
  }

  // leave tournament
  async function leaveTournamentApi(tournId, token) {
    try {
      let result = await leaveTournament(tournId, token);
      notify(result.message);
      setIsJoined(result.message);
      setIsLoading(!isLoading);
    } catch (error) {
      notify1(error.response.data.message);
      setIsLoading(!isLoading);
    }
  }

  useEffect(() => {
    tournDetails(id, token);
    tournLeaserBoard(id, token);
  }, [isLoading, id, token]);

  return (
    <>
      <Navbar title={t("The War")} />
      <div className="myTournaments" style={{ paddingTop: "150px" }}>
        <Container fluid>
          <SideMenuBar sideBar={sideBar} showSideBar={showSideBar} />
          <Row>
            <ToastContainer />
            {isLoading ? (
              <div
                style={{ height: "100vh" }}
                className="justify-center d-flex align-items-center"
              >
                <Loading />
              </div>
            ) : (
              <>
                <Col xs={12} md={4} lg={3} className="mt-4">
                  <div className="mb-3 text-center side-section">
                    <img className="avatar" src={side_avatar} alt="avatar" />
                    <span className="kunai">
                      <img
                        className="mx-2 game_img"
                        src={tournamentDetails?.game?.logo}
                        alt="GameBackground"
                      />
                      {tournamentDetails?.game?.name}
                    </span>
                    <Row className="m-auto mt-3 w-100">
                      <Col xs={12} md={12} lg={12} xxl={12}>
                        <div className="text-center">
                          <img src={person} alt="person" className="mx-2" />
                          <span className="kunai">
                            {tournamentDetails?.joined_users} {t("Joined")}
                          </span>
                        </div>
                      </Col>
                    </Row>

                    {/* countdown */}
                    <CountDown inputDate={tournamentDetails?.end_date} />
                    {/* countdown */}

                    <div className="row war-btns">
                      {isJoined ? (
                        <button
                          onClick={() => {
                            navigate(
                              `/battles-tournaments/tournaments/thewar/${tournamentDetails?.id}/${tournamentDetails?.game?.id}`
                            );
                          }}
                          className="first-btn"
                        >
                          {t("play")}
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            JoinTournament(tournamentDetails.id, token);

                            localStorage.setItem(
                              "tourn_id",
                              tournamentDetails.id
                            );
                            localStorage.setItem(
                              "game_id",
                              tournamentDetails?.game?.id
                            );
                          }}
                          className="first-btn"
                        >
                          {t("join")}
                        </button>
                      )}
                      {isJoined ? (
                        <button
                          onClick={() => leaveTournamentApi(id, token)}
                          className="second-btn"
                        >
                          {t("Leave")}
                        </button>
                      ) : (
                        <></>
                      )}
                      <Modal1
                        tournamentDetails={tournamentDetails}
                        show={show}
                        setShow={setShow}
                      />
                      <div className="d-flex">
                        <button
                          onClick={() => setShowModal(true)}
                          className="third-btn"
                        >
                          {t("prize")}
                        </button>
                        <button
                          onClick={() => setShow(true)}
                          className="third-btn "
                        >
                          {t("rules")}
                        </button>
                      </div>
                      <Modal3
                        tournamentDetails={tournamentDetails}
                        showModal={showModal}
                        setShowModal={setShowModal}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={8} lg={9} className="mt-4">
                  <div className="war-section">
                    {tournamentboard.length > 0 ? (
                      <>
                        <Row>
                          <Col xs={0} md={2} lg={3}></Col>
                          {tournamentboard.length > 1 ? (
                            <Col xs={4} md={2} lg={2} className="text-center">
                              <p className="winner-number">2</p>
                              <AiFillCaretDown style={{ color: "white" }} />
                            </Col>
                          ) : null}
                          <Col xs={4} md={3} lg={2} className="text-center">
                            <img className="crown" src={crown} alt="crown" />
                          </Col>
                          {tournamentboard.length > 2 ? (
                            <Col xs={4} md={2} lg={2} className="text-center">
                              <p className="winner-number">3</p>
                              <AiFillCaretDown style={{ color: "white" }} />
                            </Col>
                          ) : null}
                        </Row>
                        <Row className="m-auto mb-5 winners">
                          <Col md={1} lg={3}></Col>
                          {tournamentboard.length > 1 ? (
                            <Col
                              xs={4}
                              md={3}
                              lg={2}
                              className="p-0 mb-4 winner"
                            >
                              <div
                                className="winners-img"
                                style={{
                                  width: "80%",
                                  backgroundSize: "contain",
                                  margin: "auto",
                                }}
                              >
                                <img
                                  className="top-winner-img rounded-circle"
                                  src={`http://api.gamerclub.live${tournamentboard[1]?.avatar}`}
                                  alt="winner"
                                />
                                <h2 className="mb-2">
                                  {tournamentboard[1]?.name}
                                </h2>
                                {tournamentboard[1]?.score ? (
                                  <h2 className="w-auto">
                                    Score : {tournamentboard[1]?.score}
                                  </h2>
                                ) : null}
                              </div>
                            </Col>
                          ) : null}

                          <Col xs={4} md={4} lg={2} className="p-0 mb-4 winner">
                            <div className="winners-img img-winner">
                              <img
                                className="first-winner rounded-circle"
                                src={`http://api.gamerclub.live${tournamentboard[0]?.avatar}`}
                                alt="winner"
                              />
                              <h2 className="mb-2 title-winner">
                                {tournamentboard[0]?.name}
                              </h2>
                              {tournamentboard[0]?.score ? (
                                <h2 className="w-fit">
                                  Score : {tournamentboard[0]?.score}
                                </h2>
                              ) : null}
                            </div>
                          </Col>
                          {tournamentboard.length > 2 ? (
                            <Col xs={4} md={3} lg={2} className="p-0 winner">
                              <div
                                className="winners-img"
                                style={{
                                  width: "80%",
                                  backgroundSize: "contain",
                                  margin: "auto",
                                }}
                              >
                                <img
                                  className="top-winner-img rounded-circle"
                                  src={`http://api.gamerclub.live${tournamentboard[2]?.avatar}`}
                                  alt="winner"
                                />
                                <h2 className="mb-2 title-winner">
                                  {tournamentboard[2]?.name}
                                </h2>
                                {tournamentboard[2]?.score ? (
                                  <h2 className="w-fit">
                                    Score : {tournamentboard[2]?.score}
                                  </h2>
                                ) : null}
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                        <div className="pt-3 winners-list">
                          {tournamentboard.slice(3, 10).map((board, idx) => (
                            <Row
                              className="m-auto mb-3 w-100 align-items-center"
                              key={board.id}
                            >
                              <Col xs={1} md={1} lg={1} className="text-center">
                                <h4>{(idx += 4)}</h4>
                              </Col>
                              <Col xs={7} md={7} lg={8} className="p-0">
                                <div className="winner-section">
                                  <div className="winner-img">
                                    <img
                                      className="list-img"
                                      src={`http://api.gamerclub.live${board?.avatar}`}
                                      alt="img"
                                    />
                                  </div>
                                  <h2>{board?.name}</h2>
                                </div>
                              </Col>
                              <Col className="p-0 m-0" xs={3} md={4} lg={3}>
                                <div className="star-btn">
                                  <img src={star} alt="img" />
                                  {board.score}
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </>
                    ) : (
                      <h2 className="no-leaderboard">
                        {t("There Is no LeaderBoard....")}
                      </h2>
                    )}
                  </div>
                </Col>
              </>
            )}
          </Row>
          <Row className="mb-4 text-center">
            <Col lg={12} className="m-auto">
              <button
                onClick={() => navigate("/battles-tournaments/tournamnets")}
                className="back-btn"
              >
                {t("back")}
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TheWar;
