import React, { useState } from "react";
import "./signIn.css";
import side_img from "../../assets/forms/side_signin_img.png";
import google_img from "../../assets/forms/google_verify.png";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import FormsAside from "../../Components/FormsAside/FormsAside";
import { otpCode } from "../../Utilies/api";
import { notify } from "../../Components/ToastComponent";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { signinForm } from "../../Utilies/gamerClubApi";

const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // lodaing state
  const [lodaing, setLodaing] = useState(true);
  // errors messages state
  const [errorMsg, setErrorMsg] = useState("");
  // user state
  const [phone, setPhone] = useState("");

  // send data to api
  async function sendData() {
    try {
      const formData = {
        phone,
      };
      setLodaing(false);
      await signinForm(formData);
      notify("SignIn Successfully");
      localStorage.setItem("userPhone", formData.phone);
      setLodaing(false);
      setErrorMsg("");
      setTimeout(() => {
        navigate("/otp");
      }, 1500);
    } catch (error) {
      setErrorMsg(error.response.data.message);
      setLodaing(true);
    }
  }

  return (
    <div className="signUp">
      <ToastContainer />
      <Row
        className="mx-0"
        dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
      >
        <FormsAside side_img={side_img} />
        <Col xs={12} md={7} lg={8} xl={8} className="form">
          <Row className="h-10" style={{paddingTop:"10%"}}>
            <Col className="m-auto" xs={12} md={8} lg={6}>
              <h1>{t("signin")}</h1>
              {/* <div className="google">
                <img src={google_img} alt="google" className="mx-2" />
                {t("Sign In With Google")}
              </div>
              <span>{t("-OR-")}</span> */}
              {errorMsg ? (
                <div className="alert alert-danger">{errorMsg}</div>
              ) : (
                ""
              )}
              <form className="signUp-form">
                <input
                  dir={
                    localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"
                  }
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="tel"
                  name="phone"
                  placeholder={t("phone number")}
                  required
                />
              </form>
              <div className="submit-info">
                <button className="btn-submit" onClick={() => sendData()}>
                  {lodaing ? (
                    t("signin")
                  ) : (
                    <i className="fas fa-spinner fa-spin"></i>
                  )}
                </button>
                <h3>
                  {t("dont have account?")}{" "}
                  <Link to="/signup">{t("signup")}</Link>
                </h3>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SignIn;
