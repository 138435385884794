import React, { useEffect, useState } from "react";
import "./navbar.css";
import logo from "../../assets/gamer_club_logo.png";
import logo2 from "../../assets/dzo-logo.png";

import menu_icon from "../../assets/menu_icon.png";
import user_coin from "../../assets/gamer_token.svg";
// import user_heart from "../../assets/sideBar/golden_heart.png";

import SideMenuBar from "../SideMenuBar/SideMenuBar";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../Language-selector.jsx";
import { getUserCoins } from "../../Utilies/gamerClubApi.js";

const NavbarComp = ({ title }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // get user token
  const token = localStorage.getItem("token");
  // get userId
  let userId = localStorage.getItem("user_id");
  let  hostName = window.location.hostname;
  let isDzo= false;

  // get userImage from local storage
  let userImage = JSON.parse(localStorage.getItem("userImage"));

  // toggle navigation bar
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };

  const [CoinsData, setCoinsData] = useState(0);
  let userCoins = async (token) => {
    try {
      if (token) {
        let result = await getUserCoins(token);
        setCoinsData(result);
      }
    } catch (error) {
      // localStorage.clear();
    }
  };

  // call user coins api

  useEffect(() => {
   
    if(hostName =="dzo.gamerclub.live"){
      isDzo=true;
    }
    else{
      isDzo=false;
    }
  }, []);
  useEffect(() => {
    userCoins(token);
  }, [token]);

  return (
    <>
      <Navbar className="nav-comp" fixed="top">
        <Container fluid>
          <Row className="m-auto w-100">
            <Col xs={2} md={2} lg={4}>
              <div className="menu-icon">
                <img
                  src={menu_icon}
                  alt="menu"
                  className="menu_icon"
                  onClick={() => showSideBar()}
                />
              </div>
            </Col>
            <Col xs={3} md={6} lg={4}>
              <div className="text-center logo">
                {title ? (
                  <h1 className="nav-title">{title}</h1>
                ) : (
                  <img className="play_it" src={ logo2 }  alt="play it" />
                )}
              </div>
            </Col>
            {!token && !userId ? (
              <Col xs={7} md={4} lg={4} className="p-0">
                <div className="buttons">
                  <Link className="btn-sign-up" to="/signUp">
                    {t("signup")}
                  </Link>
                  <Link
                    style={{
                      backgroundColor: "transparent",
                      color: "white",
                      padding: "5px 10px",
                    }}
                    className="btn-sign-up"
                    to="/signIn"
                  >
                    {t("signin")}
                  </Link>
                  <LanguageSelector />
                </div>
              </Col>
            ) : (
              <Col xs={7} md={4} lg={4}>
                <div className="user-login-info">
                  <div className="user-login-img">
                    <Link to="/profile">
                      <img
                        className="user-img rounded-circle"
                        src={`http://api.gamerclub.live${userImage}`}
                        alt="user-img"
                      />
                    </Link>
                  </div>
                  <div className="user-login-coins">
                    <div className="user-coins">
                      {CoinsData.balance}
                      <img className="mx-2" src={user_coin} alt="coin" />
                    </div>
                    {/* <div className="user-coins">
                      {CoinsData?.lives}
                      <img
                        className="user-heart"
                        src={user_heart}
                        alt="heart"
                      />
                    </div> */}
                    <button
                      data-quantity={0}
                      className="cart-icon"
                      onClick={() => navigate("/paymentContent")}
                    >
                      <svg
                        className="icon-cart"
                        viewBox="0 0 24.38 30.52"
                        height="30.52"
                        width="24.38"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Go To Cart</title>
                        <path
                          transform="translate(-3.62 -0.85)"
                          d="M28,27.3,26.24,7.51a.75.75,0,0,0-.76-.69h-3.7a6,6,0,0,0-12,0H6.13a.76.76,0,0,0-.76.69L3.62,27.3v.07a4.29,4.29,0,0,0,4.52,4H23.48a4.29,4.29,0,0,0,4.52-4ZM15.81,2.37a4.47,4.47,0,0,1,4.46,4.45H11.35a4.47,4.47,0,0,1,4.46-4.45Zm7.67,27.48H8.13a2.79,2.79,0,0,1-3-2.45L6.83,8.34h3V11a.76.76,0,0,0,1.52,0V8.34h8.92V11a.76.76,0,0,0,1.52,0V8.34h3L26.48,27.4a2.79,2.79,0,0,1-3,2.44Zm0,0"
                        />
                      </svg>
                      <span className="quantity" />
                    </button>
                    <LanguageSelector />
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </Navbar>
      {/* SideMenuBar */}
      <SideMenuBar showSideBar={showSideBar} sideBar={sideBar} />
    </>
  );
};

export default NavbarComp;
