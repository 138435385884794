import React, { useEffect, useState } from "react";
import close_btn from "../../assets/sideBar/close_btn.png";
import profile_pic from "../../assets/sideBar/profile_pic.png";
import user_coin from "../../assets/gamer_token.svg";
// import user_heart from "../../assets/sideBar/golden_heart.png";
import log_Out_icon from "../../assets/sideBar/logOut_btn.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./sideMenuBar.css";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  getUserCoins,
  getUserDetails,
  userLogout,
} from "../../Utilies/gamerClubApi";

const SideMenuBar = ({ sideBar, showSideBar }) => {
  const { t } = useTranslation();
  const dirLang = localStorage.getItem("i18nextLng");
  // navigate
  const navigate = useNavigate();
  // get user token
  const token = localStorage.getItem("token");
  // logout function
  const handleLogOut = async (token) => {
    try {
      await userLogout(token);
      localStorage.clear();
      navigate("/signIn", { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  let userId = localStorage.getItem("user_id");
  // store user data
  const [CoinsData, setCoinsData] = useState("");
  const [userData, setUserData] = useState("");

  let userCoins = async (token) => {
    try {
      if (token) {
        let result = await getUserCoins(token);
        setCoinsData(result);
      }
    } catch (error) {}
  };

  let userDataObject = async (token) => {
    try {
      if (token) {
        let result = await getUserDetails(token);
        setUserData(result);
      }
    } catch (error) {}
  };

  useEffect(() => {
    userCoins(token);
    userDataObject(token);
  }, [token]);

  return (
    <Row
      className="side-bar"
      style={
        sideBar === true && dirLang === "ar"
          ? { right: "0", width: "100%" }
          : sideBar === true && dirLang === "en"
          ? { left: "0", width: "100%" }
          : { display: "none" }
      }
    >
      <Col xs={12} md={7} lg={6} xl={4} className="p-0 m-0 side-bar-content">
        <img
          style={
            localStorage.getItem("i18nextLng") === "en" ||
            Object.keys(userData).length > 0
              ? { right: "10px" }
              : localStorage.getItem("i18nextLng") === "ar"
              ? { left: "10px" }
              : { left: "10px" }
          }
          className="sidebar-close"
          src={close_btn}
          alt="close"
          onClick={() => showSideBar()}
        />
        <div
          className="user-data"
          style={userId || token ? { height: "150px" } : { height: "0px" }}
        >
          {userId && token ? (
            <div className="user-details">
              <div className="user-info">
                <div className="mb-2 user-img">
                  <Link to="/profile">
                    <img
                      className=" pointer rounded-circle"
                      src={
                        userData.avatar
                          ? `http://api.gamerclub.live${userData.avatar}`
                          : profile_pic
                      }
                      alt="user"
                    />
                  </Link>
                </div>
              </div>
              <div className="user-name">
                <span>{userData.name}</span>
                <span>ID : {userData.id}</span>
                <span className="user-email">{userData.email}</span>
                <div className="coins">
                  <span>
                    {CoinsData.balance}
                    <img src={user_coin} alt="coin" />
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="nav-links">
          <div className="mt-3 nav-link">
            <NavLink to="/">{t("home")}</NavLink>
          </div>
          <div className="nav-link">
            <NavLink to="/store">{t("store")}</NavLink>
          </div>
          <div className="nav-link">
            <NavLink to="/html5games">{t("html 5 games")}</NavLink>
          </div>
          <div className="nav-link">
            <NavLink to="/android">{t("android games")}</NavLink>
          </div>
          <div className="nav-link">
            <NavLink to="/battles-tournaments/tournamnets">
              {t("Tournaments")}
            </NavLink>
          </div>
          {/* <div className="nav-link">
            <NavLink to="/mytournaments">my tournaments</NavLink>
          </div> */}
          <div className="nav-link">
            <NavLink to="/payorplay/all">{t("pay or play")}</NavLink>
          </div>
        </div>

        {token ? (
          <div className="user-custom">
            <button
              className="settings-btn"
              onClick={() => handleLogOut(token)}
            >
              <img src={log_Out_icon} alt="log_Out_icon" />
              <span className="lable">{t("Log Out")}</span>
            </button>
            <button
              className="settings-btn"
              onClick={() => navigate("/settings")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                viewBox="0 0 20 20"
                height={20}
                fill="none"
                className="svg-icon"
              >
                <g strokeWidth="1.5" strokeLinecap="round" stroke="#000">
                  <circle r="3.5" cy={10} cx={10} />
                  <path
                    fillRule="evenodd"
                    d="m8.39079 2.80235c.53842-1.51424 2.67991-1.51424 3.21831-.00001.3392.95358 1.4284 1.40477 2.3425.97027 1.4514-.68995 2.9657.82427 2.2758 2.27575-.4345.91407.0166 2.00334.9702 2.34248 1.5143.53842 1.5143 2.67996 0 3.21836-.9536.3391-1.4047 1.4284-.9702 2.3425.6899 1.4514-.8244 2.9656-2.2758 2.2757-.9141-.4345-2.0033.0167-2.3425.9703-.5384 1.5142-2.67989 1.5142-3.21831 0-.33914-.9536-1.4284-1.4048-2.34247-.9703-1.45148.6899-2.96571-.8243-2.27575-2.2757.43449-.9141-.01669-2.0034-.97028-2.3425-1.51422-.5384-1.51422-2.67994.00001-3.21836.95358-.33914 1.40476-1.42841.97027-2.34248-.68996-1.45148.82427-2.9657 2.27575-2.27575.91407.4345 2.00333-.01669 2.34247-.97026z"
                    clipRule="evenodd"
                  />
                </g>
              </svg>
              <span className="lable">{t("Settings")}</span>
            </button>
          </div>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};

export default SideMenuBar;
