import React from "react";
import "./formsAside.css";
import { Col, Row } from "react-bootstrap";
import logo from "../../assets/gamerclub our marketplace-03.png";
import { useNavigate } from "react-router-dom";

const FormsAside = ({ side_img }) => {
  const navigate = useNavigate();
  return (
    <Col xs={12} md={5} lg={4} xl={4} className="aside text-center">
      <Row >
       {/*  <Col lg={12} > */}
          <img
            className="logo w-100 h-100 mt-4"
            src={logo}
            alt="logo"
            onClick={() => navigate("/")}
          />
       {/*  </Col> */}
        {/* <Col lg={12}>
          <img className="angry_birds" src={side_img} alt="angry_birds" />
        </Col> */}
      </Row>
    </Col>
  );
};

export default FormsAside;
