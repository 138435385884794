import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./wishListTable.css";
import { ToastContainer } from "react-toastify";
import { notify } from "../ToastComponent";
import { useTranslation } from "react-i18next";
import {
  deleteUserWishlistItem,
  getUserWishlist,
  postCartItem,
} from "../../Utilies/gamerClubApi";

const WishListTable = ({ toggle }) => {
  const { t } = useTranslation();
  const [cart, setCart] = useState([]);
  // get user token
  const token = localStorage.getItem("token");

  // get user cart items
  async function getWishlist(token) {
    try {
      let result = await getUserWishlist(token);
      setCart(result.data);
    } catch (error) {}
  }

  // delete wishlist item function
  async function deleteWishListItem(voucherId, token) {
    try {
      setCart(cart.filter((item) => item.id !== voucherId));
      await deleteUserWishlistItem(voucherId, token);
      notify("Your Item Deleted Successfully");
    } catch (error) {}
  }

  // handle change
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleOptionChange = (option) => {
    if (selectedOptions.includes(option.id)) {
      setSelectedOptions(
        selectedOptions.filter((selectedId) => selectedId !== option.id)
      );
    } else {
      setSelectedOptions([...selectedOptions, option.id]);
    }
  };
  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      const allOptionIds = cart.map((option) => option[option.type].id);
      setSelectedOptions(allOptionIds);
    } else {
      setSelectedOptions([]);
    }
  };

  async function addToCart(sectionType, token, productId, cartItemType) {
    let formData = {};
    if (productId.length > 1) {
      formData.items = [];
      for (let index = 0; index < productId.length; index++) {
        let prod_id = productId[index];
        formData.items.push({
          object_id: prod_id,
          qty: 1,
          type: cartItemType,
        });
      }
    } else {
      formData = {
        items: [
          {
            object_id: productId[0],
            qty: 1,
            type: cartItemType,
          },
        ],
      };
    }
    await postCartItem(formData, sectionType, token);
  }

  useEffect(() => {
    getWishlist(token);
  }, [token]);

  return (
    <div className={toggle === 3 ? "show-content game-history" : "content"}>
      <div className="wishList-table">
        <Col lg={12}>
          <h1
            style={cart.length > 0 ? { display: "block" } : { display: "none" }}
            className="wish-heading"
            onClick={() => {
              if (selectedOptions.length > 0) {
                addToCart("cart", token, selectedOptions, "voucher");
                notify("Added To Cart");
              }
            }}
          >
            {t("add to cart")}
          </h1>
        </Col>
        <Row className="wishlist">
          <ToastContainer />
          {cart.length > 0 ? (
            <Col lg={12} className="p-0">
              <div className="position-heading">
                <input
                  type="checkbox"
                  className="heading-inp-check"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
                <h4 className="wishlist-heading" name="allSelect">
                  {t("select all")}
                </h4>
              </div>
            </Col>
          ) : (
            ""
          )}
          <Col md={12} lg={12}>
            {cart.length > 0 ? (
              cart.map((cartItem) => (
                <Row key={cartItem.id}>
                  <Col xs={12} md={12} lg={12}>
                    <Col md={12} className="mb-4">
                      <Row>
                        <Col xs={12} md={8} lg={10} className="wishlist-item">
                          <Row>
                            <Col xs={5} md={3} lg={2} className="text-center">
                              <img
                                className="wishlist-game-img"
                                src={
                                  cartItem[cartItem.type].icon
                                    ? cartItem[cartItem.type].icon
                                    : cartItem[cartItem.type].productImage
                                }
                                alt=""
                              />
                              <input
                                className="inp-check"
                                type="checkbox"
                                checked={selectedOptions.includes(
                                  cartItem[cartItem.type].id
                                )}
                                onChange={() =>
                                  handleOptionChange(cartItem[cartItem.type])
                                }
                                style={
                                  localStorage.getItem("i18nextLng") === "en"
                                    ? { left: "5px" }
                                    : { right: "5px" }
                                }
                              ></input>
                            </Col>
                            <Col xs={7} md={9} lg={10} className="p-0">
                              <div className="wishlist-game">
                                <h3>
                                  {cartItem[cartItem.type].title}
                                  {cartItem[cartItem.type].name}
                                  {cartItem[cartItem.type].productName}
                                </h3>
                                <p>It’s a playit voucher of fun is unmatched</p>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={4} lg={2}>
                          <div className="wishlist-item-price">
                            <span className="wishlist-price">
                              {cartItem[cartItem.type]?.sellPrice
                                ? cartItem[cartItem.type]?.sellPrice
                                : cartItem[cartItem.type].price *
                                  cartItem.qty}{" "}
                              {cartItem[cartItem.type].currency}{" "}
                              {cartItem[cartItem.type].productCurrency}
                            </span>
                            <span
                              className="wishlist-remove"
                              onClick={() =>
                                deleteWishListItem(cartItem.id, token)
                              }
                            >
                              {t("Remove")}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
              ))
            ) : (
              <h2 className="empty-cart">
                {t("No Items In Your Wishing List !")}
              </h2>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WishListTable;
